import React, { Fragment, useState } from 'react'
import Checkbox from '../UI/Checkbox/Checkbox'
import * as styles from './NewsletterForm.module.scss'
import MailchimpSubscribe from 'react-mailchimp-subscribe'
import axios from 'axios'

const url =
  'https://hakanhellstrom.us14.list-manage.com/subscribe/post?u=fca601d027569e1cbf721afae&id=69bf70314d'

const NewsletterForm = ({ text }: { text: string }) => {
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState(false)
  const [success, setSuccess] = useState(false)
  const [statusMessage, setStatusMessage] = useState('')
  const [closeMessage, setClosedMessage] = useState(false)
  const [email, setEmail] = useState('')

  const onChange = (e: any) => {
    setEmail(e.target.value)
  }

  const onSubmit = async ({ email }: { email: string }) => {
    if (email) {
      try {
        setLoading(true)
        const response: any = await axios(
          `/.netlify/functions/signup-newsletter`,
          {
            method: 'POST',
            data: {
              email: email,
            },
          }
        )
        if (response && response.data && response.data.message) {
          const test = response.data.message.split('__jp0(')

          if (test.length > 2) {
            handleStatusMessage('error', '')
          } else {
            const removeJP = response.data.message
              .replace('__jp0(', '')
              .slice(0, -1)
            const json = JSON.parse(removeJP)

            if (json && json.result) {
              if (json.result === 'success') {
                setSuccess(true)
              }

              handleStatusMessage(json.result, json.msg)
            }
          }

          setLoading(false)
        }
        // setSuccess(true)
      } catch (error) {
        console.log('ERRROR', error)
        setLoading(false)
        setSuccess(false)
      }
    }
  }

  const handleStatusMessage = (status: any, message: any) => {
    console.log('handleStatusMessage', status, message)

    if (status === 'success') {
      setError(false)
      setStatusMessage(message)
    } else {
      setError(true)
      setStatusMessage(
        'Något gick fel. Försök igen om 5 minuter eller med en annan mail.'
      )
    }
  }
  console.log('statusMessage', statusMessage)
  return (
    <Fragment>
      {statusMessage && !closeMessage && (
        <div className={styles.status}>
          <div className={styles.inner}>
            <span className={styles.heading}>
              {error
                ? 'Något Gick fel'
                : statusMessage === 'Tack för att du prenumererar!'
                ? 'Du är redan registrerad!'
                : 'Tack'}
            </span>
            <p className={styles.message}>{statusMessage}</p>
            <button
              onClick={() => {
                setClosedMessage(true)
              }}
              className={styles.close}
            >
              <svg
                width="14"
                height="14"
                viewBox="0 0 14 14"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M1.4 14L0 12.6L5.6 7L0 1.4L1.4 0L7 5.6L12.6 0L14 1.4L8.4 7L14 12.6L12.6 14L7 8.4L1.4 14Z"
                  fill="#02277C"
                />
              </svg>
            </button>
          </div>
        </div>
      )}
      <div className={styles.wrapper}>
        <form
          onSubmit={(e: any) => {
            e.preventDefault()
            onSubmit({ email: email })
          }}
        >
          <div className={styles.top}>
            <div>
              <input
                className={styles.input}
                type="email"
                name="mail"
                onChange={onChange}
                required
                placeholder="Din e-mail"
              />
              <Checkbox onChange={() => {}} error={false} required name="terms">
                <span className="body-text">{text}</span>
              </Checkbox>
            </div>
          </div>
          <div className={styles.bottom}>
            <div className={styles.bg}></div>
            <div>
              <button disabled={success} className="button">
                <span>
                  {success === true
                    ? 'Tack'
                    : loading
                    ? 'Laddar'
                    : 'Prenumerera'}
                </span>
                <div>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="40"
                    height="30"
                    viewBox="0 0 40 30"
                    fill="none"
                  >
                    <path
                      d="M24.825 0.0999756L39.075 14.35V15.65L24.825 29.9L21.925 27L32.025 17H0.924988V13.05H32.075L21.925 2.99998L24.825 0.0999756Z"
                      fill="#D9D9D9"
                    />
                  </svg>
                </div>
              </button>
            </div>
          </div>
        </form>

        {/* <form onSubmit={onSubmit}>
        <input
          className={styles.input}
          type="email"
          name="mail"
          required
          placeholder="Din e-mail"
        />
        <input
          type="submit"
          className={styles.button}
          value={'Signa upp mig'}
        />

        <Checkbox error={false} required onChange={checkOnChange} name="terms">
          <span>
            Jag godkänner härmed att min emailadress behandlas av FKP Scorpio
            Sverige för att jag ska kunna ta emot nyhetsbrev och information av
            dem. Jag bekräftar att jag är över 16 år, och har i annat fall mina
            föräldrars godkännande att prenumerera på nyhetsbrevet{' '}
            <a href="https://www.fkpscorpio.se/datapolicy/" target="_blank">
              datapolicy
            </a>
          </span>
        </Checkbox>
      </form> */}
      </div>
    </Fragment>
  )
}

export default NewsletterForm
